
































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import MultiLevel from '@/app/ui/components/MultiLevel/index.vue'
import FormLevel from '../components/FormLevel/index.vue'
import MembershipTextInput from '../components/MembershipTextInput/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import MembershipDatePicker from '../components/MembershipDatePicker/index.vue'
import ButtonBase from '@/app/ui/components/Button/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import ModalConfirmation from '../components/ModalConfirmation/index.vue'
import StepHistory from '@/app/ui/components/StepHistory/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import { minValue, required, requiredIf, ValidationRule } from 'vuelidate/lib/validators'
import dayjs from 'dayjs'
import {
  DetailLoyaltyProgramMembership,
  EnumStatusLoyaltyMembership
} from '@/domain/entities/LoyaltyProgramMembership'
import { Form, History } from '@/app/ui/views/LoyaltyProgram/Membership/useCase/interfaces'
import useActionLoyaltyMembership from '@/app/ui/views/LoyaltyProgram/Membership/useCase'
import controller from '@/app/ui/controllers/LoyaltyProgramMembershipController'
import { EventBusConstants } from '@/app/infrastructures/misc'
import { validationMixin } from 'vuelidate'

export interface IFormLevel {
  id?: number | string
  name: string
  minAmount: string | number
}

interface ValidationsInterface {
  form: {
    sttPrefix: {
      required: () => ValidationRule
    }
    shipmentIDs: {
      required: ValidationRule
    }
    packageStatuses: {
      required: () => ValidationRule
    }
    backwardMonthPeriod: {
      required: () => ValidationRule
      minValue: ValidationRule
    }
    startDate: {
      required: () => ValidationRule
    }
    startTime: {
      required: () => ValidationRule
    }
    endDate: {
      required: () => ValidationRule
    }
    endTime: {
      required: () => ValidationRule
    }
    levelRule: {
      $each: {
        isMoreThanPrevLevel: (val: IFormLevel, values: IFormLevel[]) => boolean
        name: {
          required: () => ValidationRule
        }
        minAmount: {
          required: () => ValidationRule
          minValue: ValidationRule
        }
      }
    }
  }
}

@Component({
  name: 'EditLoyaltyProgramMembershipPage',
  mixins: [validationMixin],
  components: {
    Badge,
    EditIcon,
    MultiLevel,
    FormLevel,
    MembershipTextInput,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    LoadingOverlay,
    MembershipDatePicker,
    ButtonBase,
    ModalConfirmation,
    StepHistory,
  },
})
export default class EditLoyaltyProgramMembershipPage extends Vue {
  controller = controller
  useAction = useActionLoyaltyMembership()
  form: Form = this.useAction.form

  // isValidateFormLevel when user uncheck the minimal checkbox but the value still there before,
  // it's going to be auto checked
  // set true when submit save, set false when user do something on component form level
  isValidateFormLevel = false
  histories: History[] = this.useAction.histories
  modalConfirmation = {
    visible: false,
    title: '',
    subtitle: '',
    buttonOK: '',
  }
  todayDate = dayjs().format('YYYY-MM-DD')
  isValidate = false

  ObjectMultiLevel = [
    {
      prefix: 'Level 1 (Lowest)',
      isOpen: false,
      isError: this.isLevelError(0, 'all'),
    },
    {
      prefix: 'Level 2',
      isOpen: false,
      isError: this.isLevelError(1, 'all'),
    },
    {
      prefix: 'Level 3',
      isOpen: false,
      isError: this.isLevelError(2, 'all'),
    },
    {
      prefix: 'Level 4',
      isOpen: false,
      isError: this.isLevelError(3, 'all'),
    },
    {
      prefix: 'Level 5 (Highest)',
      isOpen: false,
      isError: this.isLevelError(4, 'all'),
    },
  ]

  created(): void {
    controller.getOne(+this.$route.params.id)
    this.ObjectMultiLevel = this.ObjectMultiLevel.map((item) => {
      return {
        ...item,
        isError: this.isLevelError(4, 'all'),
        isOpen: Boolean(this.isLevelError(4, 'all'))
      }
    })
  }

  @Watch('controller.detailLoyaltyMembership')
  onChangeDataDetail(val: DetailLoyaltyProgramMembership ): void {
    this.form = this.useAction.setDataDetail(val)
    this.histories = this.useAction.setHistories(val)
    if (val && val.info && val.dateSchedules && val.levels) {
      this.isValidate = true
    }
  }

  @Watch('controller.errSave')
  onChangeErrSave(val: string): void {
    if (val !== '' && val === EventBusConstants.CREATE_LOYALTY_PROGRAM_MEMBERSHIP) {
      this.$router.push({ name: 'LoyaltyProgramMembershipListPage' })
    }
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        sttPrefix: {
          required,
        },
        shipmentIDs: {
          required: requiredIf(() => {
            return !this.form.isWithoutShipmentNonCA
          }),
        },
        packageStatuses: {
          required,
        },
        backwardMonthPeriod: {
          required,
          minValue: minValue(1),
        },
        startDate: {
          required,
        },
        startTime: {
          required,
        },
        endDate: {
          required,
        },
        endTime: {
          required,
        },
        levelRule: {
          $each: {
            isMoreThanPrevLevel: (val: IFormLevel, values: IFormLevel[]) =>
              this.isMoreThanPrevLevel(val, values),
            name: {
              required,
            },
            minAmount: {
              required,
              minValue: minValue(0),
            },
          },
        },
      },
    }
  }

  private isMoreThanPrevLevel(val: IFormLevel, values: IFormLevel[]): boolean {
    const currIdx = values.findIndex((item: IFormLevel) => item.id === val.id)
    const prevSchedule = values[currIdx - 1]?.minAmount
    if (prevSchedule) {
      return val.minAmount > prevSchedule
    }
    if (currIdx !== 0) {
      return val.minAmount > 0
    }
    return true
  }

  private toggleNonCA(): void {
    this.form.isWithoutShipmentNonCA = !this.form.isWithoutShipmentNonCA
  }

  disablePeriodStartDate(statusProgram: string): boolean {
    return [
      EnumStatusLoyaltyMembership.ACTIVE,
      EnumStatusLoyaltyMembership.PAUSED
    ].includes(<EnumStatusLoyaltyMembership>statusProgram.toUpperCase())
  }

  public showModalSubmit(): void {
    if (
      !this.$v.form.$invalid &&
      !this.hasDuplicateMonth('all') &&
      !this.isDateScheduleEmpty()
    ) {
      this.modalConfirmation = {
        visible: true,
        title: 'Want to Save Changes?',
        subtitle: 'Make sure the data are correct before you save program',
        buttonOK: 'Save',
      }
    } else {
      this.$notify({
        title: 'Create Loyalty Membership Failed',
        text: 'Mohon cek kembali setiap kolom dan pastikan semua telah terisi',
        type: 'error',
        duration: 5000,
      })
    }
    this.isValidateFormLevel = true
    this.ObjectMultiLevel = this.ObjectMultiLevel.map((item, index) => {
      return {
        ...item,
        isOpen: Boolean(this.isLevelError(index, 'all')),
        isError: this.isLevelError(index, 'all'),
      }
    })
  }

  public showModalCancel(): void {
    this.modalConfirmation = {
      visible: true,
      title: 'Cancel Changes The Program?',
      subtitle: 'Data changes to your program will not be saved',
      buttonOK: 'Yes',
    }
  }

  private closeModal(): void {
    this.modalConfirmation = {
      visible: false,
      title: '',
      subtitle: '',
      buttonOK: '',
    }
  }

  private onSubmit(): void {
    if (this.modalConfirmation.buttonOK === 'Save') {
      controller.update({
        body: this.useAction.generatePayloadSave(this.form),
        id: +this.$route.params.id,
      })
      this.closeModal()
    } else {
      this.$router.push('/loyalty-program/membership')
    }
  }

  private isLevelError(
    index: number,
    type: 'all' | 'name' | 'minAmount'
  ): boolean | string | undefined {
    const v = this.$v
    if (v && this.isValidate) {
      const form = v.form
      if (form) {
        const levelRuleIndex = form.levelRule
        if (levelRuleIndex) {
          const eachIndex = levelRuleIndex.$each[index]
          if (eachIndex) {
            switch (type) {
              case 'all':
                return eachIndex.$invalid
              case 'name':
                if (eachIndex.name.$invalid) {
                  return 'Must be filled'
                }
                return undefined
              case 'minAmount':
                if (eachIndex.minAmount.$invalid) {
                  return 'Must be filled'
                }
                if (!eachIndex.isMoreThanPrevLevel) {
                  return 'Minimum amount here should be higher than on the previous level'
                }
                return undefined
            }
          }
        }
      }
    }
    return undefined
  }

  get moreStartDate(): string {
    return dayjs(this.form.startDate || this.todayDate).format('YYYY-MM-DD')
  }

  private hasDuplicateMonth(
    type: 'all' | 'date1' | 'date2' | 'date3' | 'date4'
  ): boolean {
    const destructDate = (date: string): string | undefined => {
      return (
        date
          .split('-')
          .splice(1, 2)
          .join('/') || undefined
      )
    }
    const dataArray = [
      destructDate(this.form.date1.val),
      destructDate(this.form.date2.val),
      destructDate(this.form.date3.val),
      destructDate(this.form.date4.val),
    ]
    if (type !== 'all') {
      const dataFilter = dataArray
        .filter((item, index) => {
          return index !== Number(type.replace(/date/, '')) - 1
        })
        .filter(item => {
          return item !== undefined
        })
      return dataFilter.includes(destructDate(this.form[type].val))
    }
    const isUnique = dataArray
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .filter(a => {
        return a !== undefined
      })
    const dataClean = dataArray.filter(item => {
      return item !== undefined
    })
    return dataClean.length !== isUnique.length
  }

  private isDateScheduleEmpty(): boolean {
    const dataArray = [
      this.form.date1,
      this.form.date2,
      this.form.date3,
      this.form.date4,
    ].filter((item) => {
      return item.val !== ''
    })
    return dataArray.length <= 0
  }

  private onInputLevel(
    val: string,
    index: number,
    type: 'name' | 'minAmount'
  ): void {
    this.isValidateFormLevel = false
    this.form.levelRule[index][type] = val
  }

  private NolIgnore(e: number | string): void {
    if (e === 0) {
      this.form.backwardMonthPeriod = ''
    }
  }

  private toggleMultiLevel(index: number): void {
    this.isValidateFormLevel = false
    this.ObjectMultiLevel[index].isOpen = !this.ObjectMultiLevel[index].isOpen
  }

  getStatus(statusProgram: string): EnumStatusLoyaltyMembership {
    return <EnumStatusLoyaltyMembership>statusProgram.toUpperCase()
  }
  setBadgeTypeProgramStatus(): string {
    return this.useAction.setBadgeTypeProgramStatus(this.getStatus(<string>this.form.status))
  }

  private openMultiLevel(index: number): void {
    this.ObjectMultiLevel[index].isOpen = true
  }

  @Watch('form.levelRule', { deep: true })
  onFormLevelChange(): void {
    this.ObjectMultiLevel = this.ObjectMultiLevel.map((item, index) => {
      return {
        ...item,
        isError: this.isLevelError(index, 'all'),
        isOpen: !item.isOpen ? Boolean(this.isLevelError(index, 'all')) : item.isOpen
      }
    })
  }

}
